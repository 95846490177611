import React from 'react';
import { Table } from 'react-bootstrap';
import styles from './table.module.scss';
import content from '../../../lang/es.json';

const RTable = ({ tabla, tn, tr, nearestLonLat, vs30, currentVs30 }) => {
  return (
    <div>
      <div className={styles.descripcion_tabla}>
        <b>{content.web.report.body.table.title}</b>{' '}
        {content.web.report.body.table.description1}(
        <b>{nearestLonLat.length > 0 ? nearestLonLat[1].toFixed(3) : 0}</b>,{' '}
        <b>{nearestLonLat.length > 0 ? nearestLonLat[0].toFixed(3) : 0}</b>){' '}
        {currentVs30 === -1
          ? content.web.report.body.table.description2.slice(0, -2)
          : content.web.report.body.table.description2}{' '}
        <b>
          {currentVs30 === -1
            ? 'según mapa de suelo'
            : vs30[0]?.toLowerCase() + vs30.substring(1)}
        </b>
        {content.web.report.body.table.description3}
      </div>
      <Table borderless responsive>
        <thead>
          <tr className={styles.table_header}>
            <th className={styles.table_header_TR}>
              T<sub>r</sub> (años)
            </th>
            <th>PGV (cm/s)</th>
            <th>PGA (g)</th>
            <th>Sa(0.03s) (g)</th>
            <th>Sa(0.06s) (g)</th>
            <th>Sa(0.1s) (g)</th>
            <th>Sa(0.2s) (g)</th>
            <th>Sa(0.3s) (g)</th>
            <th>Sa(0.6s) (g)</th>
            <th>Sa(1s) (g)</th>
            <th>Sa(2s) (g)</th>
            <th>Sa(3.5s) (g)</th>
            <th>Sa(5s) (g)</th>
            <th>Sa(10s) (g)</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(tabla).map((key) => {
            return (
              <tr key={key}>
                <th className={styles.table_body_fcol}>{key}</th>

                {Object.keys(tabla[key])
                  .sort(function (a, b) {
                    return a - b;
                  })
                  .map((ns) => {
                    if (tr === +key && tn === +ns) {
                      // el signo + transforma string en numero
                      return (
                        <td className={styles.td_highlight} key={key + ns}>
                          {tabla[key][ns].toFixed(4)}
                        </td>
                      );
                    } else {
                      return <td key={key + ns}>{tabla[key][ns].toFixed(4)}</td>;
                    }
                  })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default RTable;
