import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lodash from 'lodash';
import { NavMenuComponent } from '../components';
import { getData, setSettings } from '../redux';

const NavMenuContainer = ({
  changeLoaderClassList,
  setValue,
  value,
  debouncedValue,
  setPopoverIsShown,
  setFullFooterIsShown,
  showSearchResults,
  setShowSearchResults,
}) => {
  const _ = Lodash;

  const dispatch = useDispatch();
  const { filters, currentTn, currentTr, currentVs30 } = useSelector(
    (state) => state.settings
  );

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  const handleFilterSwitchMode = (e, ind, subInd) => {
    setFullFooterIsShown(false);
    // setPopoverIsShown(false);
    const checkedInd = checkIfOnlyOneChecked(ind);
    const arr = _.cloneDeep(filters);

    if (checkedInd === subInd) {
      return;
    } else {
      changeLoaderClassList(false);
      [...arr][ind].values.map((el) => (el.value = false));
    }

    dispatch(
      setSettings({
        filters: [...arr].map((el, i) =>
          i === ind
            ? {
                ...el,
                values: el.values.map((subEl, subI) =>
                  subI === subInd ? { ...subEl, value: !subEl.value } : { ...subEl }
                ),
              }
            : { ...el }
        ),
        currentTn: ind === 0 ? arr[0].values[subInd].name : currentTn,
        currentTr: ind === 1 ? arr[1].values[subInd].name : currentTr,
        currentVs30: ind === 2 ? arr[2].values[subInd].name : currentVs30,
      })
    );

    setTimeout(() => {
      changeLoaderClassList(true);
    }, 1);

    // setValue(value === 1 ? 2 : 1);
    setValue(value + 1);
  };

  useEffect(() => {
    if (debouncedValue) {
      dispatch(getData());
    }
  }, [debouncedValue]);

  const checkIfOnlyOneChecked = (ind) => {
    let count = filters[ind].values.filter((el) => el.value === true).length;
    if (count === 1) {
      let trueInd = filters[ind].values.findIndex((el) => el.value === true);
      return trueInd;
    } else {
      return -1;
    }
  };

  const handleSearchField = (e) => {
    setSearchValue(e.target.value);
  };

  const handleMapSearch = () => {
    if (searchValue.length > 3) {
      setSearching(true);
      fetch(
        `https://nominatim.openstreetmap.org/search?q=${searchValue}&countrycodes=cl&format=json`
      )
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data);
          setShowSearchResults(true);
          setSearching(false);
        });
    }
  };

  useEffect(() => {
    if (searchValue.length <= 3) {
      setShowSearchResults(false);
    }
  }, [searchValue]);

  const handleSearchResultClick = (e, lat, lon) => {
    setShowSearchResults(false);

    dispatch(setSettings({ flyToLatLon: [lat, lon] }));
  };

  return (
    <NavMenuComponent
      handleFilterSwitchMode={handleFilterSwitchMode}
      showSearchResults={showSearchResults}
      searchResults={searchResults}
      handleSearchResultClick={handleSearchResultClick}
      searchValue={searchValue}
      handleSearchField={handleSearchField}
      handleMapSearch={handleMapSearch}
      searching={searching}
    />
  );
};

export default NavMenuContainer;
