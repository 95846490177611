import React from 'react';
import { TimecounterComponent } from '../components';
import { useSelector } from 'react-redux';

const TimecounterContainer = ({ debouncedValue, value }) => {
  const { loading } = useSelector((state) => state.data);

  return (
    <TimecounterComponent
      loading={loading}
      debouncedValue={debouncedValue}
      value={value}
    />
  );
};

export default TimecounterContainer;
