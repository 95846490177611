import React from 'react';
import { FoooterComponent } from '../components';

const FooterContainer = ({
  fullFooterIsShown,
  setFullFooterIsShown,
  fullFooterTabIndex,
  setFullFooterTabIndex,
}) => {
  return (
    <FoooterComponent
      fullFooterIsShown={fullFooterIsShown}
      setFullFooterIsShown={setFullFooterIsShown}
      fullFooterTabIndex={fullFooterTabIndex}
      setFullFooterTabIndex={setFullFooterTabIndex}
    />
  );
};

export default FooterContainer;
