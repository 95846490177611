import React, { useEffect, useState } from 'react';
import { PopoverComponent } from '../components';
import { useSelector } from 'react-redux';

const PopoverContainer = ({
  handleShowReport,
  popoverIsShown,
  setPopoverIsShown,
  chosenLonLat,
  currentTn,
  currentTr,
  vs30Label,
}) => {
  const { pickedMapValue, currentVs30 } = useSelector((state) => state.settings);
  const { loading, data } = useSelector((state) => state.data);

  const [tn, setTn] = useState(currentTn);
  const [tr, setTr] = useState(currentTr);
  const [vs30, setVs30] = useState(currentVs30);
  const [vs30Lab, setVs30Lab] = useState(currentVs30);

  useEffect(() => {
    if (!loading) {
      setTn(currentTn);
      setTr(currentTr);
      setVs30(currentVs30);
      setVs30Lab(vs30Label);
      // setPopoverIsShown(false);
    }
  }, [data]);

  return (
    <PopoverComponent
      handleShowReport={handleShowReport}
      popoverIsShown={popoverIsShown}
      setPopoverIsShown={setPopoverIsShown}
      chosenLonLat={chosenLonLat}
      currentTn={tn}
      currentTr={tr}
      pickedMapValue={pickedMapValue}
      vs30Label={vs30Lab}
      currentVs30={vs30}
    />
  );
};

export default PopoverContainer;
