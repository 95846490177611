import { GET_ERRORS, CLEAR_ERRORS } from './types';

const initialState = {
  msg: '',
  code: null,
  id: null,
};

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        code: action.payload.code,
        id: action.payload.id,
      };

    case CLEAR_ERRORS:
      return {
        msg: '',
        code: null,
        id: null,
      };

    default:
      return state;
  }
}
