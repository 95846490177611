import {
  GET_INFO,
  GET_QID,
  INFO_IS_LOADING,
  INFO_LOADED,
  SET_TABLA,
  SET_G1,
  SET_G2,
  GET_SIGNATURE,
  GET_REPORT,
  SET_REPORT,
} from './types';

const initialState = {
  info: [],
  tabla: {},
  g1: [],
  g2: [],
  signature:'',
  report:'',
  qid:'',
  infoIsLoading: false,
};

export default function infoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INFO:
      return {
        ...state,
        info: action.payload,
        infoIsLoading: false,
      };
      case GET_QID:
      return {
        ...state,
        qid: action.payload,
        infoIsLoading: false,
      };
    case GET_REPORT:
      return {
        ...state,
        report: action.payload,
        infoIsLoading: false,
      };  
      case SET_REPORT:
      return {
        ...state,
        ...action.payload,
      };
    case GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        infoIsLoading: false,
      };
    case SET_TABLA:
      return {
        ...state,
        tabla: action.payload,
      };
    case SET_G1:
      return {
        ...state,
        g1: action.payload,
      };
    case SET_G2:
      return {
        ...state,
        g2: action.payload,
      };

    case INFO_LOADED:
      return {
        ...state,
        infoIsLoading: false,
      };

    case INFO_IS_LOADING:
      return {
        ...state,
        infoIsLoading: true,
      };

    default:
      return state;
  }
}
