import { useReducer, useState, useRef, useEffect, useLayoutEffect } from 'react';
import Plot from 'react-plotly.js';
import content from '../../../lang/es.json';
import {
  chart_g1_config,
  chart_g1_logx_config,
  chart_g1_logy_config,
  chart_g1_logxy_config,
  chart_g2_config,
  chart_g2_logx_config,
  chart_g2_logy_config,
  chart_g2_logxy_config,
  defaultChartWidth,
  maxResolutionForOneChart,
  // containerWidth,
} from '../../../constants';
import styles from './charts.module.scss';

const Charts = ({ g1, g2, nearestLonLat }) => {
  const [logx1, setLogx1] = useState(true);
  const [logy1, setLogy1] = useState(true);
  const [logx2, setLogx2] = useState(false);
  const [logy2, setLogy2] = useState(false);

  const chartsContainerRef = useRef(0);
  const [chartWidth, setChartWidth] = useState(defaultChartWidth);

  function handleLog(graph, axis) {
    if (graph === 'g1') {
      if (axis === 'x') {
        if (logx1 === false) {
          setLogx1(true);
        } else {
          setLogx1(false);
        }
      }
      if (axis === 'y') {
        if (logy1 === false) {
          setLogy1(true);
        } else {
          setLogy1(false);
        }
      }
    } else {
      if (axis === 'x') {
        if (logx2 === false) {
          setLogx2(true);
        } else {
          setLogx2(false);
        }
      }
      if (axis === 'y') {
        if (logy2 === false) {
          setLogy2(true);
        } else {
          setLogy2(false);
        }
      }
    }
  }

  useLayoutEffect(() => {
    const w = chartsContainerRef.current.offsetWidth;
    if (window.innerWidth <= maxResolutionForOneChart) {
      setChartWidth(w);
    } else {
      setChartWidth(Math.floor((w - 35) / 2));
    }
  }, [chartsContainerRef.current.offsetWidth]);

  return (
    <>
      <div>{content.web.report.body.resultados2.text}</div>
      <div className={styles.charts} id='charts-container' ref={chartsContainerRef}>
        <div className={styles.chart}>
          <div className={styles.chart_header}>
            <div className={styles.chart_header_switches}>
              <div className={styles.switch_form}>
                <label className={styles.switch}>
                  <input
                    type='checkbox'
                    onChange={() => handleLog('g1', 'x')}
                    checked={logx1}
                  />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
                <span>LogX</span>
              </div>
              <div className={styles.switch_form}>
                <label className={styles.switch}>
                  <input
                    type='checkbox'
                    onChange={() => handleLog('g1', 'y')}
                    checked={logy1}
                  />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
                <span>LogY</span>
              </div>
            </div>
          </div>
          {g1 && (
            <div className={styles.chart_card}>
              <div>
                {!logx1 && !logy1 ? (
                  <Plot
                    className='chart'
                    data={g1}
                    layout={{ ...chart_g1_config, width: chartWidth }}
                  />
                ) : null}
                {logx1 && !logy1 ? (
                  <Plot
                    className='chart'
                    data={g1}
                    layout={{ ...chart_g1_logx_config, width: chartWidth }}
                  />
                ) : null}
                {!logx1 && logy1 ? (
                  <Plot
                    className='chart'
                    data={g1}
                    layout={{ ...chart_g1_logy_config, width: chartWidth }}
                  />
                ) : null}
                {logx1 && logy1 ? (
                  <Plot
                    className='chart'
                    data={g1}
                    layout={{ ...chart_g1_logxy_config, width: chartWidth }}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div className={styles.chart}>
          <div className={styles.chart_header}>
            <div className={styles.chart_header_switches}>
              <div className={styles.switch_form}>
                <label className={styles.switch}>
                  <input
                    type='checkbox'
                    onChange={() => handleLog('g2', 'x')}
                    checked={logx2}
                  />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
                <span>LogX</span>
              </div>
              <div className={styles.switch_form}>
                <label className={styles.switch}>
                  <input
                    type='checkbox'
                    onChange={() => handleLog('g2', 'y')}
                    checked={logy2}
                  />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
                <span>LogY</span>
              </div>
            </div>
          </div>
          {g2 && (
            <div className={styles.chart_card}>
              <div>
                {!logx2 && !logy2 ? (
                  <Plot
                    className='chart'
                    data={g2}
                    layout={{ ...chart_g2_config, width: chartWidth }}
                  />
                ) : null}
                {logx2 && !logy2 ? (
                  <Plot
                    className='chart'
                    data={g2}
                    layout={{ ...chart_g2_logx_config, width: chartWidth }}
                  />
                ) : null}
                {!logx2 && logy2 ? (
                  <Plot
                    className='chart'
                    data={g2}
                    layout={{ ...chart_g2_logy_config, width: chartWidth }}
                  />
                ) : null}
                {logx2 && logy2 ? (
                  <Plot
                    className='chart'
                    data={g2}
                    layout={{ ...chart_g2_logxy_config, width: chartWidth }}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.description_charts}>
        <b>{content.web.report.body.charts.description.title} </b>
        {content.web.report.body.charts.description.description1}(
        <b>{nearestLonLat.length > 0 ? nearestLonLat[1].toFixed(3) : 0}</b>,{' '}
        <b>{nearestLonLat.length > 0 ? nearestLonLat[0].toFixed(3) : 0})</b>{' '}
        {content.web.report.body.charts.description.description2}
      </div>
    </>
  );
};

export default Charts;
