import React, { useState } from 'react';
import { ReportComponent } from '../components';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTabla, getCharts, getInfo, getReport, setReport } from '../redux';

const ReportContainer = ({
  reportIsShown,
  setReportIsShown,
  tnLabel,
  trLabel,
  vs30Label,
}) => {
  const dispatch = useDispatch();
  const { chosenLonLat, nearestLonLat, currentTr, currentTn, currentVs30 } = useSelector(
    (state) => state.settings
  );
  const { info, tabla, g1, g2, infoIsLoading, signature, report } = useSelector(
    (state) => state.info
  );
  const [isAcceptConditions, setIsAcceptConditions] = useState(false);

  useEffect(() => {
    if (reportIsShown && chosenLonLat.length) {
      dispatch(getInfo());
    }
  }, [reportIsShown]);

  useEffect(() => {
    if (info.data) {
      dispatch(getTabla());
      dispatch(getCharts());
    }
  }, [info]);

  useEffect(() => {
    dispatch(setReport({ report: '' }));
    if (isAcceptConditions) {
      dispatch(getReport());
    }
  }, [isAcceptConditions]);
  return (
    <ReportComponent
      data={info}
      report={report}
      tn={currentTn}
      tr={currentTr}
      tabla={tabla}
      g1={g1}
      g2={g2}
      reportIsShown={reportIsShown}
      setReportIsShown={setReportIsShown}
      nearestLonLat={nearestLonLat}
      infoIsLoading={infoIsLoading}
      signature={signature}
      tnLabel={tnLabel}
      trLabel={trLabel}
      vs30Label={vs30Label}
      isAcceptConditions={isAcceptConditions}
      setIsAcceptConditions={setIsAcceptConditions}
      currentVs30={currentVs30}
    />
  );
};

export default ReportContainer;
