import React from 'react';
import styles from './index.module.scss';
import FooterSmall from '../../icons/footer.png';
import content from '../../lang/es.json';
import partners from '../../icons/partners.png';

const FooterComponent = ({
  fullFooterIsShown,
  setFullFooterIsShown,
  fullFooterTabIndex,
  setFullFooterTabIndex,
}) => {
  return (
    <>
      {fullFooterIsShown ? (
        <div className={styles.fullinfo}>
          {fullFooterIsShown ? (
            <div
              className={styles.button}
              onClick={() => {
                setFullFooterIsShown(!fullFooterIsShown);
                setFullFooterTabIndex(0);
              }}
            >
              <span className='material-icons'>expand_more</span>
            </div>
          ) : null}
          <div className={styles.content}>
            <div className={styles.tabstitle}>
              <div
                className={
                  styles.tabstitlebutton +
                  ' ' +
                  (fullFooterTabIndex === 0 ? styles.active : '')
                }
                onClick={() => {
                  setFullFooterTabIndex(0);
                }}
              >
                {content.web.footer.tabs.credits.title}
              </div>
              <div
                className={
                  styles.tabstitlebutton +
                  ' ' +
                  (fullFooterTabIndex === 1 ? styles.active : '')
                }
                onClick={() => {
                  setFullFooterTabIndex(1);
                }}
              >
                {content.web.footer.tabs.assumptions.title}
              </div>{' '}
              <div
                className={
                  styles.tabstitlebutton +
                  ' ' +
                  (fullFooterTabIndex === 2 ? styles.active : '')
                }
                onClick={() => {
                  setFullFooterTabIndex(2);
                }}
              >
                {content.web.footer.tabs.about.title}
              </div>{' '}
            </div>
            <div className={styles.tabs}>
              <div
                className={
                  styles.tab + ' ' + (fullFooterTabIndex !== 0 ? styles.hidden : '')
                }
              >
                <p className={styles.text}>{content.web.footer.tabs.credits.text1}</p>
                <p className={styles.text}>{content.web.footer.tabs.credits.text2}</p>
                <h6>{content.web.footer.tabs.credits.atrtitle}</h6>
                <p className={styles.text}>
                  {content.web.footer.tabs.credits.atrtext.map((el) => {
                    return (
                      <span key={el} className={styles.text}>
                        {el}
                        <br />
                      </span>
                    );
                  })}
                </p>
              </div>
              <div
                className={
                  styles.tab + ' ' + (fullFooterTabIndex !== 1 ? styles.hidden : '')
                }
              >
                <div className={styles.scroll_content}>
                  {content.web.footer.tabs.assumptions.text.map((el) => {
                    return (
                      <p key={el} className={styles.text}>
                        {el}
                      </p>
                    );
                  })}
                  <h6>{content.web.footer.tabs.assumptions.reftitle}</h6>
                  <ul>
                    {content.web.footer.tabs.assumptions.reftext.map((el) => {
                      return (
                        <li key={el} className={styles.text}>
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div
                className={
                  styles.tab + ' ' + (fullFooterTabIndex !== 2 ? styles.hidden : '')
                }
              >
                <div className={styles.scroll_content}>
                  {content.web.footer.tabs.about.text.map((el) => {
                    return (
                      <p key={el} className={styles.text}>
                        {el}
                      </p>
                    );
                  })}
                  <div className={styles['partners']}>
                    <h6>{content.web.footer.tabs.about.partners}</h6>
                    <div className={styles['partners_image_container']}>
                      <img
                        className={styles['partners_image']}
                        src={partners}
                        alt='partners icons'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.container}>
        {!fullFooterIsShown ? (
          <div
            className={styles.button}
            onClick={() => setFullFooterIsShown(!fullFooterIsShown)}
          >
            <span className='material-icons'>expand_less</span>
          </div>
        ) : null}

        <hr className={styles.footerline} />
        <p className={styles.copyright}>{content.web.footer.static.copyright}</p>
        <div className={styles.rightblock}>
          <p className={styles.impulsan}>{content.web.footer.static.icon_title}</p>
          <img src={FooterSmall} alt={'footer-small'} className={styles.img}></img>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
