import React from 'react';
import { ErrorComponent } from '../components';

class ErrorContainer extends React.Component {
  state = {
    showModal: false,
  };

  static getDerivedStateFromError(error) {
    return { showModal: true };
  }

  static hideModal() {
    return { showModal: false };
  }

  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  logErrorToServices = console.log;

  render() {
    if (this.state.showModal) {
      return (
        <ErrorComponent
          showModal={this.state.showModal}
          hideModal={this.hideModal}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorContainer;
