import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';
import {
  CurrentValuesComponent,
  ErrorComponent,
  MapComponent,
  LegendComponent,
} from '../components';
import {
  dataLoadDelayOnMapMove,
  maxZoom,
  minZoom,
  pixeslToRegionId,
  initialMapCoords,
  activeMinimapPosition,
  initMiniMapFramePosition,
} from '../constants';
import {
  MiniMapContainer,
  NavMenuContainer,
  PopoverContainer,
  ReportContainer,
  TimecounterContainer,
  FooterContainer,
} from '../containers';
import { elementsDefaultFill, getElementId } from '../helpers';
import { setDataRange, setSettings } from '../redux';

const Home = () => {
  const [value, setValue] = useState(0);
  const debouncedValue = useDebounce(value, dataLoadDelayOnMapMove);

  const { loading } = useSelector((state) => state.data);
  const [reportIsShown, setReportIsShown] = useState(false);
  const [miniMapIsShown, setMiniMapIsShown] = useState(false);
  const [popoverIsShown, setPopoverIsShown] = useState(false);
  const [fullFooterIsShown, setFullFooterIsShown] = useState(false);
  const [fullFooterTabIndex, setFullFooterTabIndex] = useState(0);
  const [legendIsShown, setLegendIsShown] = useState(true);

  const {
    chosenLonLat,
    currentTn,
    currentTr,
    currentVs30,
    currentZoom,
    filters,
    mapIsReady,
  } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const handleShowReport = () => {
    if (chosenLonLat.length) {
      setReportIsShown(true);
      setPopoverIsShown(false);
    }
  };

  const flyToInitPosistion = () => {
    dispatch(setSettings({ flyToZoom: minZoom }));
    dispatch(setSettings({ flyToLatLon: initialMapCoords }));
  };

  useEffect(() => {
    document.getElementsByClassName('timecounter-item')[0].classList.add('firstload');
  }, [loading]);

  const [tnLabel, setTnLabel] = useState(
    filters[0].values.find((v) => v.name === currentTn).alias
  );
  const [trLabel, setTrLabel] = useState(
    filters[1].values.find((v) => v.name === currentTr).alias + ' años'
  );
  const [vs30Label, setVs30Label] = useState(
    filters[2].values.find((v) => v.name === currentVs30).alias
  );

  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    if (reportIsShown) {
      setShowSearchResults(false);
    }
  }, [reportIsShown]);

  useEffect(() => {
    const value = filters[0].values.find((v) => v.name === currentTn).alias;
    setTnLabel(value);
  }, [currentTn, filters]);
  useEffect(() => {
    const value = filters[1].values.find((v) => v.name === currentTr).alias + ' años';
    setTrLabel(value);
  }, [currentTr, filters]);
  useEffect(() => {
    const value = filters[2].values.find((v) => v.name === currentVs30).alias;
    setVs30Label(value);
  }, [currentVs30, filters]);

  useEffect(() => {
    if (miniMapIsShown) {
      document.getElementById('active-minimap').style.transition = '1s';
    } else {
      document.getElementById('active-minimap').style.transition = '0s';
    }
  }, [miniMapIsShown]);

  // endpoint disabled
  useEffect(() => {
    dispatch(setDataRange());
  }, []);

  const changeLoaderClassList = (flag) => {
    if (loading) {
      document.getElementsByClassName('timecounter-item')[0].classList.add('firstload');
    } else {
      document
        .getElementsByClassName('timecounter-item')[0]
        .classList.remove('firstload');
    }

    if (!loading && flag) {
      document.getElementsByClassName('timecounter-item')[0].classList.add('counting');
    } else {
      document.getElementsByClassName('timecounter-item')[0].classList.remove('counting');
    }
  };

  const dragElement = (element, dragzone) => {
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    //MouseUp occurs when the user releases the mouse button
    const dragMouseUp = () => {
      document.onmouseup = null;
      //onmousemove attribute fires when the pointer is moving while it is over an element.
      document.onmousemove = null;

      element.classList.remove('drag');
    };

    const dragMouseMove = (event) => {
      event.preventDefault();
      //clientX property returns the horizontal coordinate of the mouse pointer
      pos1 = pos3 - event.clientX;
      //clientY property returns the vertical coordinate of the mouse pointer
      pos2 = pos4 - event.clientY;
      pos3 = event.clientX;
      pos4 = event.clientY;
      if (element.offsetTop >= -10 && element.offsetTop <= 270) {
        element.style.top = `${element.offsetTop - pos2}px`;
        document.getElementById('active-minimap').style.top =
          initMiniMapFramePosition -
          (element.offsetTop - pos2) -
          activeMinimapPosition +
          'px';
        document.getElementById('active-minimap').style.transition = '0s';
      } else {
        if (element.offsetTop < -10) {
          element.style.top = '-10px';
        }
        if (element.offsetTop > 270) {
          element.style.top = '270px';
        }
      }
      //offsetTop property returns the top position relative to the parent

      // elementsDefaultFill();
      // dispatch(setSettings({ activeRegions: [...getElementId(element.offsetTop)] }));
    };

    const dragMouseDown = (event) => {
      // if (event.target.id === 'minimap-frame') {
      if (
        event.target.id === 'active-minimap-container' ||
        event.target.id === 'active-minimap'
      ) {
        event.preventDefault();

        pos3 = event.clientX;
        pos4 = event.clientY;

        element.classList.add('drag');

        document.onmouseup = dragMouseUp;
        document.onmousemove = dragMouseMove;
      } else {
        return;
      }
    };

    dragzone.onmousedown = dragMouseDown;
  };

  // useEffect(() => {
  //   if (!loading) {
  //     document.getElementsByClassName('timecounter-item')[0].classList.add('counting');
  //   }
  // }, [value]);

  useEffect(() => {
    if (mapIsReady) {
      const dragable = document.getElementById('minimap-frame');
      const dragzone = document.getElementById('minimap-dragzone');

      dragElement(dragable, dragzone);
    }
  }, [mapIsReady]);

  return (
    <>
      <link
        href='https://fonts.googleapis.com/icon?family=Material+Icons'
        rel='stylesheet'
      ></link>
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,200'
      />
      <ErrorComponent />
      <TimecounterContainer debouncedValue={debouncedValue} value={value} />
      <NavMenuContainer
        changeLoaderClassList={changeLoaderClassList}
        setValue={setValue}
        value={value}
        debouncedValue={debouncedValue}
        setPopoverIsShown={setPopoverIsShown}
        setFullFooterIsShown={setFullFooterIsShown}
        showSearchResults={showSearchResults}
        setShowSearchResults={setShowSearchResults}
      />
      <MapComponent
        setPopoverIsShown={setPopoverIsShown}
        changeLoaderClassList={changeLoaderClassList}
        setValue={setValue}
        value={value}
        debouncedValue={debouncedValue}
        popoverIsShown={popoverIsShown}
        zoomPercent={Math.ceil(((currentZoom - minZoom) / (maxZoom - minZoom)) * 100)}
        setFullFooterIsShown={setFullFooterIsShown}
        setShowSearchResults={setShowSearchResults}
        legendIsShown={legendIsShown}
        setLegendIsShown={setLegendIsShown}
        setminimapIsShown={setMiniMapIsShown}
        minimapIsShown={miniMapIsShown}
        flyToInitPosistion={flyToInitPosistion}
      />
      <ReportContainer
        setReportIsShown={setReportIsShown}
        reportIsShown={reportIsShown}
        tnLabel={tnLabel}
        trLabel={trLabel}
        vs30Label={vs30Label}
      />
      {chosenLonLat.length ? (
        <PopoverContainer
          currentTn={currentTn}
          currentTr={currentTr}
          handleShowReport={handleShowReport}
          popoverIsShown={popoverIsShown}
          setPopoverIsShown={setPopoverIsShown}
          chosenLonLat={chosenLonLat}
          vs30Label={vs30Label}
        />
      ) : null}
      <CurrentValuesComponent tnLabel={tnLabel} trLabel={trLabel} vs30Label={vs30Label} />
      <MiniMapContainer
        miniMapIsShown={miniMapIsShown}
        setMiniMapIsShown={setMiniMapIsShown}
        setValue={setValue}
        value={value}
      />
      <LegendComponent
        legendIsShown={legendIsShown}
        setLegendIsShown={setLegendIsShown}
      />
      <FooterContainer
        fullFooterIsShown={fullFooterIsShown}
        setFullFooterIsShown={setFullFooterIsShown}
        fullFooterTabIndex={fullFooterTabIndex}
        setFullFooterTabIndex={setFullFooterTabIndex}
      />
    </>
  );
};

export default Home;
