import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';

const CurrentValuesComponent = ({ tnLabel, trLabel, vs30Label }) => {
  const [tn, SetTn] = useState(tnLabel);
  const [tr, SetTr] = useState(trLabel);
  const [vs30, SetVs30] = useState(vs30Label);

  const { data, loading } = useSelector((state) => state.data);

  useEffect(() => {
    if (!loading) {
      SetTn(tnLabel);
      SetTr(trLabel);
      SetVs30(vs30Label);
    }
  }, [data]);

  return (
    <div className={styles['currentvalues']}>
      <div className={styles['row']}>
        <div className={styles['tuneicon']}>
          <span className='material-icons'>tune</span>
        </div>
        <div>{tn}</div>
        <div className={styles['middle']}>{tr}</div>
        <div>{vs30}</div>
      </div>
    </div>
  );
};

export default CurrentValuesComponent;
