import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MiniMapComponent } from '../components';
import {
  miniMapMin,
  miniMapMax,
  chileMinLat,
  chileMaxLat,
  chileCenterPoints,
  miniMapFrameHeight,
  activeMinimapPosition,
  initMiniMapFramePosition,
} from '../constants';
import { setSettings } from '../redux';
import { elementsDefaultFill, getElementId } from '../helpers';

const MiniMapContainer = ({ miniMapIsShown, setMiniMapIsShown, setValue, value }) => {
  const dispatch = useDispatch();
  const { mapCenter, mapIsReady, activeRegions } = useSelector((state) => state.settings);
  const miniMapRange = miniMapMax - miniMapMin;
  const chileGeoRange = chileMaxLat - chileMinLat;
  const pixelsToCoords = chileGeoRange / miniMapRange;
  const coordsToPixels = miniMapRange / chileGeoRange;

  const frameRef = useRef();

  const imgLeaveHandler = (e) => {
    elementsDefaultFill(activeRegions);
  };

  const imgHoverHandler = (e) => {
    elementsDefaultFill(activeRegions);
    getElementId(e.nativeEvent.layerY);
  };

  const imgClickHandler = (e, dragged = false) => {
    let yToConvert;

    // elementsDefaultFill();

    if (dragged) {
      yToConvert = document.getElementById('minimap-frame').offsetTop + 20;
    } else {
      e.stopPropagation();
      yToConvert = e.nativeEvent.layerY;
    }

    let convertedCoord = chileMaxLat - pixelsToCoords * yToConvert;

    const lat = findClosestPoint(convertedCoord)[0];

    dispatch(setSettings({ flyToLatLon: [convertedCoord, lat] }));

    if (yToConvert >= miniMapRange - (miniMapFrameHeight / 4) * 3) {
      yToConvert = miniMapRange - (miniMapFrameHeight / 4) * 3;
    }
    if (yToConvert <= (miniMapFrameHeight / 4) * 3) {
      yToConvert = -(miniMapFrameHeight / 4);
    }
    if (
      yToConvert < miniMapRange - (miniMapFrameHeight / 4) * 3 &&
      yToConvert > (miniMapFrameHeight / 4) * 3
    ) {
      yToConvert = yToConvert - miniMapFrameHeight / 2;
    }

    document.getElementById('minimap-frame').style.top = yToConvert + 'px';

    document.getElementById('active-minimap').style.top =
      initMiniMapFramePosition - yToConvert - activeMinimapPosition + 'px';
    document.getElementById('active-minimap').style.transition = '1s';
  };

  useEffect(() => {
    const yToConvert = mapCenter.lat - chileMaxLat;
    let convertedCoord = -coordsToPixels * yToConvert - miniMapFrameHeight / 2;

    if (convertedCoord < miniMapFrameHeight / 4) {
      convertedCoord = -(miniMapFrameHeight / 4);
    }
    if (convertedCoord >= miniMapRange - (miniMapFrameHeight / 4) * 3) {
      convertedCoord = miniMapRange - (miniMapFrameHeight / 4) * 3;
    }

    document.getElementById('minimap-frame').style.top = convertedCoord + 'px';

    document.getElementById('active-minimap').style.top =
      initMiniMapFramePosition - convertedCoord - activeMinimapPosition + 'px';
    document.getElementById('active-minimap').style.transition = '1s';

    // if (value > 0) {
    //   setValue(value + 1);
    // }

    // console.log('center set');
    // if (mapIsReady) {
    //   elementsDefaultFill();
    //   dispatch(setSettings({ activeRegions: [...getElementId(convertedCoord)] }));
    // }
  }, [mapCenter]);

  const findClosestPoint = (num) => {
    var curr = [...chileCenterPoints[0]];

    for (let i = 1; i < chileCenterPoints.length; i++) {
      if (Math.abs(chileCenterPoints[i][1] - num) < Math.abs(curr[1] - num)) {
        curr = chileCenterPoints[i];
      }
    }

    return curr;
  };

  return (
    <MiniMapComponent
      imgClickHandler={imgClickHandler}
      miniMapIsShown={miniMapIsShown}
      setMiniMapIsShown={setMiniMapIsShown}
      imgHoverHandler={imgHoverHandler}
      imgLeaveHandler={imgLeaveHandler}
      frameRef={frameRef}
      setValue={setValue}
      value={value}
    />
  );
};

export default MiniMapContainer;
