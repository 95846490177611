export const zoomRadius = {
  4: 5,
  5: 5,
  6: 8,
  7: 15,
  8: 24,
  9: 24,
  10: 30,
  11: 60,
  12: 60,
};

export const pixeslToRegionId = {
  'CL-AP': [-10, 10], //1
  'CL-TA': [11, 20], //2
  'CL-AN': [21, 50], //3
  'CL-AT': [51, 60], //4
  'CL-CO': [61, 75], //5
  'CL-VS': [76, 90], //6
  'CL-RM': [91, 100], //7
  'CL-LI': [101, 108], //8
  'CL-ML': [109, 115], //9
  'CL-NU': [116, 120], //10
  'CL-BI': [121, 130], //11
  'CL-AR': [131, 140], //12
  'CL-LR': [141, 150], //13
  'CL-LL': [151, 175], //14
  'CL-AI': [176, 200], //15
  'CL-MA': [200, 270], //16
};

export const chileCenterPoints = [
  [-69.4688961073781428, -17.4978628663063773],
  [-69.3566624111253986, -19.4138181354490165],
  [-69.1385631960911979, -21.3297734045916556],
  [-68.8346469683799285, -23.2457286737342947],
  [-69.4273171239121183, -25.1616839428769339],
  [-69.6348651540622257, -27.0776392120195766],
  [-70.6402060283238882, -28.9935944811622122],
  [-70.9881629567853452, -30.9095497503048549],
  [-70.7683354654392076, -32.8255050194474904],
  [-71.1797690077549987, -34.7414602885901331],
  [-72.1035894957541075, -36.6574155577327758],
  [-72.1739044705701929, -38.5733708268754114],
  [-72.73351312257887, -40.489326096018047],
  [-72.421594673588686, -42.4052813651606897],
  [-72.2104998047639413, -44.3212366343033324],
  [-73.450143911296621, -46.237191903445968],
  [-73.4469949533596491, -48.1531471725886036],
  [-74.0602323921021366, -50.0691024417312462],
  [-71.7874827073663084, -51.9850577108738889],
  [-71.2925349102495858, -53.9010129800165245],
];
export const miniMapMin = 80;
export const miniMapMax = 380;
export const miniMapFrameHeight = 40;

export const chileMinLon = -75;
export const chileMaxLon = -66;

export const chileMinLat = -56;
export const chileMaxLat = -17;

export const flyToZoom = 7;

export const API_ENDPOINT =
  process.env['REACT_APP_API_GATEWAY_URL'] ??
  'https://8c5d8th0b1.execute-api.us-east-1.amazonaws.com/dev';

export const DATA_ENDPOINT = API_ENDPOINT + '/fgetpointsfrombb';
export const INFO_ENDPOINT = API_ENDPOINT + '/fpinpoint';
export const RANGE_ENDPOINT = API_ENDPOINT + '/fgetrange';
export const REPORT_ENDPOINT = API_ENDPOINT + '/fgetreport';

export const TRs = [100, 250, 500, 1000, 2500, 5000, 10000];
export const TNs = [-1, 0, 0.03, 0.06, 0.1, 0.2, 0.3, 0.6, 1.0, 2.0, 3.5, 5.0, 10.0];
export const VS30s = [-1, 180, 350, 500, 760, 900];

export const TRsAliases = ['100', '250', '500', '1000', '2500', '5000', '10000'];
export const TNsAliases = [
  'PGV',
  'PGA',
  'Sa (0.03 s)',
  'Sa (0.06 s)',
  'Sa (0.1 s)',
  'Sa (0.2 s)',
  'Sa (0.3 s)',
  'Sa (0.6 s)',
  'Sa (1.0 s)',
  'Sa (2.0 s)',
  'Sa (3.5 s)',
  'Sa (5.0 s)',
  'Sa (10.0 s)',
];
export const VS30sAliases = [
  'Mapa de suelo',
  '180 m/s',
  '350 m/s',
  '500 m/s',
  '760 m/s',
  '900 m/s',
];

export const defaultVs30 = 760;
export const defaultTn = 0;
export const defaultTr = 1000;

export const mapPointColor = '#2a9d8f';
export const mapPointRadius = 2;

export const filtersData = [
  {
    id: 'tn',
    values: TNs.map((el, i) => {
      return {
        alias: TNsAliases[i],
        name: el,
        value: el === defaultTn ? true : false,
      };
    }),
  },
  {
    id: 'tr',
    values: TRs.map((el, i) => {
      return {
        alias: TRsAliases[i],
        name: el,
        value: el === defaultTr ? true : false,
      };
    }),
  },
  {
    id: 'vs30',
    values: VS30s.map((el, i) => {
      return {
        alias: VS30sAliases[i],
        name: el,
        value: el === defaultVs30 ? true : false,
      };
    }),
  },
];

export const popoverAutoHideTime = 3000;

export const dataLoadDelayOnMapMove = 3000;
export const minZoom = 4;
export const maxZoom = 12;
export const minSampleLimit = 10;
export const maxSampleLimit = 100;
export const mapStyle = {
  version: 8,
  sources: {
    osm: {
      type: 'raster',
      tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
      tileSize: 256,
      attribution: '&copy; OpenStreetMap Contributors',
      maxzoom: 19,
    },
  },
  layers: [
    {
      id: 'osm',
      type: 'raster',
      source: 'osm', // This must match the source key above
    },
  ],
};
export const initialMapCoords = [-33.437954004569356, -70.65932705697341];
export const popUpHtml = [
  `<p class="map-popup-title">`,
  `</p><p class="map-popup-text">`,
  `</p><p><a class="map-popup-link" id="map-popup-link">see more</a></p>`,
];

export const rowsLimit = 499;

export const viewState = {
  container: 'map-container',
  style:
    'https://api.maptiler.com/maps/basic/style.json?key=get_your_own_OpIi9ZULNHzrESv6T2vL',
  center: initialMapCoords,
  zoom: minZoom,
};

// hexbin options
export const options = {
  opacity: 0.7,
  colorRange: [
    '#440154',
    '#482878',
    '#3e4a89',
    '#31688e',
    '#26828e',
    '#1f9e89',
    '#35b779',
    '#6dcd59',
    '#b4de2c',
    '#fde725',
  ],
  radius: 5,
  radiusRange: [5, 5],
  colorScaleExtent: [0, 1],
};

export const pinpoint_table_numeric_attributes = [
  'it_geo__longitud_i',
  'it_geo__latitud_i',
  'it_id__index_id',
  'it_haz__seismic_sa_n',
  'it_haz__seismic_pga_n',
  'it_haz__seismic_pgv_n',
  'it_haz__seismic_lambda_n',
  'it_geo__grid_level_label',
  'it_geo__grid_size_km_i',
  'it_haz__seismic_vs30_i',
  'it_haz__seismic_tn_i',
  'it_haz__seismic_tr_i',
];

export const chart_g1_config = {
  xaxis: {
    autorange: true,
    tickformat: '.3r',
    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    tickformat: '.1r',

    nticks: 5,
    title: {
      text: 'λ=1/T<sub>r</sub> (1/años)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const chart_g1_logxy_config = {
  xaxis: {
    autorange: true,
    type: 'log',
    range: [-2, 1],
    tickformat: '.1r',
    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    type: 'log',
    range: [-4, -2],
    tickformat: '.1r',
    nticks: 5,
    title: {
      text: 'λ=1/T<sub>r</sub> (1/años)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const chart_g1_logx_config = {
  xaxis: {
    autorange: true,
    type: 'log',
    range: [-2, 1],
    tickformat: '.1r',
    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    tickformat: '.1r',
    nticks: 5,
    title: {
      text: 'λ=1/T<sub>r</sub> (1/años)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const chart_g1_logy_config = {
  xaxis: {
    autorange: true,
    tickformat: '.3r',
    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    type: 'log',
    range: [-4, -2],
    tickformat: '.1r',
    nticks: 5,
    title: {
      text: 'λ=1/T<sub>r</sub> (1/años)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const chart_g2_config = {
  xaxis: {
    autorange: true,
    title: {
      text: 'T (s)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    tickformat: '.3r',

    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const chart_g2_logx_config = {
  xaxis: {
    autorange: true,
    tickformat: '.1r',
    type: 'log',
    title: {
      text: 'T (s)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    tickformat: '.3r',
    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const chart_g2_logy_config = {
  xaxis: {
    autorange: true,
    tickformat: '.1r',
    title: {
      text: 'T (s)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    tickformat: '.1r',
    type: 'log',
    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const chart_g2_logxy_config = {
  xaxis: {
    autorange: true,
    tickformat: '.1r',
    type: 'log',
    title: {
      text: 'T (s)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
  yaxis: {
    autorange: true,
    tickformat: '.1r',
    type: 'log',
    title: {
      text: 'S<sub>a</sub> (g)',
      font: {
        size: 18,
        color: '#404040',
      },
    },
  },
};

export const activeMinimapPosition = 100;
export const initMiniMapFramePosition = 106;
export const defaultChartWidth = 700;
export const maxResolutionForOneChart = 1200;

export const termsAndConds =
  'https://unicornio-public-athena-files.s3.amazonaws.com/static/itrend_mapaamenaza_terminos.pdf';
