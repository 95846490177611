import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages';
// import { Context } from './context';
import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary';
import { ErrorComponent } from './components';
import { useDispatch } from 'react-redux';
import { returnErrors } from './redux';

const App = withErrorBoundary(() => {
  // const [lang, setLang] = useState('eng');
  const dispatch = useDispatch();

  const [error, resetError] = useErrorBoundary(
    // You can optionally log the error to an error reporting service
    // (error, errorInfo) => console.log(error, errorInfo)
    (error, errorInfo) => dispatch(returnErrors(error, ''))
  );

  if (error) {
    return <ErrorComponent />;
  }

  return (
    // <Context.Provider value={{ lang, setLang }}>
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
      </Routes>
    </Router>
    // </Context.Provider>
  );
});

export default App;
