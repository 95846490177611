import React from 'react';
import './index.scss';
import CloseButton from 'react-bootstrap/CloseButton';
import content from '../../lang/es.json';

const MiniMapComponent = ({
  imgHoverHandler,
  imgClickHandler,
  miniMapIsShown,
  setMiniMapIsShown,
  imgLeaveHandler,
  frameRef,
}) => {
  return (
    <>
      <div className={miniMapIsShown ? 'minimap-container' : 'minimap-container hidden'}>
        <div className='minimap-header'>
          <div className='show-minimap-button'>
            <span id='minimapicon' className='material-icons'>
              map
            </span>
          </div>
          <p className='title'>{content.web.widgets.minimap.title}</p>

          <CloseButton
            className='minimap-close'
            onClick={() => setMiniMapIsShown(!miniMapIsShown)}
          />
        </div>
        <div id='minimap-dragzone'>
          <div
            ref={frameRef}
            className='minimap-frame'
            id='minimap-frame'
            onClick={(e) => imgClickHandler(e, true)}
          >
            <div id='active-minimap-container'>
              <img id='active-minimap' src='/chile-active.svg' alt='active-minimap' />
            </div>
          </div>
          <div
            // onMouseMove={(e) => imgHoverHandler(e)}
            // onMouseLeave={(e) => imgLeaveHandler(e)}
            onClick={(e) => imgClickHandler(e)}
            id='miniMap'
          ></div>
          <object
            title='Chile minimap'
            data='/chile.svg'
            width='100'
            height='300'
            id='miniMapSvg'
          />
        </div>
      </div>
    </>
  );
};

export default MiniMapComponent;
