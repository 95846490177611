import React, { useEffect, useState } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import { useDispatch, useSelector } from 'react-redux';
import { options } from '../../constants';
import { setCustomDataRange } from '../../redux';
import styles from './index.module.scss';
import content from '../../lang/es.json';

const LegendComponent = ({ legendIsShown, setLegendIsShown }) => {
  const { colorRange } = options;
  const { currentTn, filters, dataRange, maxDataRange } = useSelector(
    (state) => state.settings
  );
  const { data, loading } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const [unit, setUnit] = useState(currentTn === -1 ? ' [cm/s]' : ' g');

  const [minVal, setMinVal] = useState(
    currentTn === -1 ? maxDataRange[2] : maxDataRange[0]
  );
  const [maxVal, setMaxVal] = useState(
    currentTn === -1 ? maxDataRange[3] : maxDataRange[1]
  );

  const [fixedMinVal, setFixedMinVal] = useState(minVal);
  const [fixedMaxVal, setFixedMaxVal] = useState(maxVal);

  const [minCustomValue, setMinCustomValue] = useState(minVal);
  const [maxCustomValue, setMaxCustomValue] = useState(maxVal);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (!loading) {
      const minVal = currentTn === -1 ? dataRange[2] : dataRange[0];
      const maxVal = currentTn === -1 ? dataRange[3] : dataRange[1];
      const fixedMinVal = currentTn === -1 ? maxDataRange[2] : maxDataRange[0];
      const fixedMaxVal = currentTn === -1 ? maxDataRange[3] : maxDataRange[1];

      setMinVal(minVal);
      setMaxVal(maxVal);
      setUnit(currentTn === -1 ? ' [cm/s]' : ' g');

      setMinCustomValue(minVal);
      setMaxCustomValue(maxVal);
      setFixedMinVal(fixedMinVal);
      setFixedMaxVal(fixedMaxVal);

      setSelectedFilter(filters?.[0]?.values?.find((v) => v.name === currentTn));
    }

    setEditing(false);
  }, [data]);

  useEffect(() => {
    setRangeDistance(fixedMaxVal - fixedMinVal);
    setFromPosition(minCustomValue - fixedMinVal);
    setToPosition(maxCustomValue - fixedMinVal);

    currentTn === -1
      ? dispatch(setCustomDataRange(undefined, undefined, minCustomValue, maxCustomValue))
      : dispatch(
          setCustomDataRange(minCustomValue, maxCustomValue, undefined, undefined)
        );
  }, [fixedMaxVal, fixedMinVal, minCustomValue, maxCustomValue]);

  useEffect(() => {
    if (!editing) {
      setMinVal(minCustomValue);
      setMaxVal(maxCustomValue);
    } else {
      setMinVal(fixedMinVal);
      setMaxVal(fixedMaxVal);
    }
  }, [editing]);

  const [rangeDistance, setRangeDistance] = useState(fixedMaxVal - fixedMinVal);
  const [fromPosition, setFromPosition] = useState(minCustomValue - fixedMinVal);
  const [toPosition, setToPosition] = useState(maxCustomValue - fixedMinVal);

  const mouseDownHandler = (type = 'low') => {
    return (e) => {
      // Get the current mouse position
      const moveHandler = mouseMoveHandler(
        e.clientX,
        e.target.parentNode.getBoundingClientRect().left,
        e.target.parentNode.getBoundingClientRect().width,
        type
      );
      // Attach the listeners to `document`
      document.addEventListener('mousemove', moveHandler);
      document.addEventListener('mouseup', mouseUpHandler(moveHandler));
    };
  };

  const mouseMoveHandler = (origHandlerPosition, offset, width, type) => {
    return (e) => {
      // How far the mouse has been moved
      if (!e) return;
      const dx = e.clientX;
      let newValue =
        type === 'low'
          ? Math.min(
              (Math.max(dx - offset, 0) / width) * rangeDistance,
              maxCustomValue - 0.1
            )
          : Math.min(
              Math.max(
                (Math.max(dx - offset, 0) / width) * rangeDistance,
                minCustomValue + 0.1
              ),
              fixedMaxVal
            );
      newValue = Math.round(newValue * 100) / 100;
      if (!isNaN(dx) && origHandlerPosition) {
        type === 'low' ? setMinCustomValue(newValue) : setMaxCustomValue(newValue);
      }
    };
  };

  const mouseUpHandler = (moveHandler) => {
    return (e) => {
      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };
  };

  const [selectedFilter, setSelectedFilter] = useState(
    filters?.[0]?.values?.find((v) => v.name === currentTn)
  );

  return (
    <div
      className={
        legendIsShown
          ? styles['legend_top_container']
          : styles['legend_top_container'] + ' ' + styles['hidden']
      }
    >
      <div className={styles['navmenu_intensidad']}>
        <div className={styles['navmenu_intensidad_header']}>
          <div className={styles['navmenu_intensidad_header_container']}>
            <span className='material-icons'>opacity</span>
            <p className={styles['navmenu_intensidad_header_title']}>
              {content.web.widgets.intensidad.title}
            </p>
          </div>

          <CloseButton
            className={styles['close_button']}
            onClick={() => setLegendIsShown(false)}
          ></CloseButton>
        </div>
      </div>
      <div className={`${styles['legend-controls']} ${editing ? styles['editing'] : ''}`}>
        <div className={styles['legend-container-extra']}>
          <div className={styles['legend-container']}>
            {editing ? (
              <div className={styles['range-container']}>
                <div className={styles['sliders-control']}>
                  <div
                    className={styles['label']}
                    style={{
                      left: `${(minCustomValue / rangeDistance) * 100}%`,
                    }}
                    onMouseDown={mouseDownHandler('low')}
                    onMouseUp={mouseUpHandler}
                  >
                    {minCustomValue}
                  </div>

                  <div
                    className={styles['label']}
                    style={{
                      left: `${(maxCustomValue / rangeDistance) * 100}%`,
                    }}
                    onMouseDown={mouseDownHandler('high')}
                    onMouseUp={mouseUpHandler}
                  >
                    {maxCustomValue}
                  </div>

                  <span
                    className={styles.dial}
                    style={{
                      left: `${(minCustomValue / rangeDistance) * 100}%`,
                    }}
                  ></span>

                  <span
                    className={styles.dial}
                    style={{
                      left: `calc(${(maxCustomValue / rangeDistance) * 100}% - 0.5px)`,
                    }}
                  ></span>

                  <input
                    id='fromSlider'
                    type='range'
                    step={0.01}
                    value={minCustomValue}
                    min={fixedMinVal}
                    max={fixedMaxVal}
                    onInput={(event) => {
                      const [from, to] = [parseFloat(event.target.value), maxCustomValue];
                      if (from > to) {
                        setMinCustomValue(to);
                      } else {
                        setMinCustomValue(from);
                      }
                    }}
                  />
                  <input
                    id='toSlider'
                    type='range'
                    step={0.01}
                    value={maxCustomValue}
                    min={fixedMinVal}
                    max={fixedMaxVal}
                    style={{
                      background: `linear-gradient(
                      to right,
                      ${'#C6C6C6'} 0%,
                      ${'#C6C6C6'} ${(fromPosition / rangeDistance) * 100}%,
                      ${Array.from({ length: colorRange.length })
                        .map((_, i) =>
                          i < colorRange.length - 1
                            ? [colorRange[i], colorRange[i + 1]]
                            : [colorRange[i], colorRange[i]]
                        )
                        .map(
                          (pair, i) =>
                            `${pair[0]} ${
                              (fromPosition / rangeDistance +
                                ((toPosition - fromPosition) /
                                  rangeDistance /
                                  colorRange.length) *
                                  i) *
                              100
                            }%,
                            ${pair[1]} ${
                              (fromPosition / rangeDistance +
                                ((toPosition - fromPosition) /
                                  rangeDistance /
                                  colorRange.length) *
                                  (i + 1)) *
                              100
                            }%,`
                        )
                        .join('\n')}
                      ${'#C6C6C6'} ${(toPosition / rangeDistance) * 100}%, 
                      ${'#C6C6C6'} 100%)`,
                    }}
                    onInput={(event) => {
                      const [from, to] = [minCustomValue, parseFloat(event.target.value)];
                      if (from > to) {
                        setMaxCustomValue(from);
                      } else {
                        setMaxCustomValue(to);
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              colorRange.map((item, i) => {
                return i < colorRange.length - 1 ? (
                  <div
                    key={'legend-item-' + i}
                    className={styles['legend-item']}
                    style={{
                      background:
                        'linear-gradient(90deg, ' +
                        colorRange[i] +
                        ' 0%, ' +
                        colorRange[i + 1] +
                        ' 100%)',
                    }}
                  ></div>
                ) : null;
              })
            )}
          </div>
        </div>

        <div className={styles['bottom-row']} onClick={() => setEditing(!editing)}>
          <span className={styles['limit']}>
            <span>
              {minVal ?? 0}
              {unit}
            </span>
            <span className={styles['limit-label']}>min</span>
          </span>
          <span className={styles['limit']}>
            <span>
              {maxVal ?? 1}
              {unit}
            </span>
            <span className={styles['limit-label']}>max</span>
          </span>
        </div>
        <div className={styles['top-row']}>
          <div>
            {editing ? (
              <div style={{ maxWidth: '180px' }}>
                {content.web.widgets.intensidad.move_range}
              </div>
            ) : (
              <>
                <p>
                  {content.web.widgets.intensidad.unity}:{' '}
                  {selectedFilter.name > 0
                    ? 'Sa (' + selectedFilter.name + 's)'
                    : selectedFilter.alias}
                  <br />
                  {content.web.widgets.intensidad.max} {fixedMaxVal} {unit}
                </p>
              </>
            )}
          </div>
          <div className={styles['toggle-button']} onClick={() => setEditing(!editing)}>
            <span className='no-user-select'>
              {editing ? content.web.widgets.intensidad.confirm_scale : 'Editar'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegendComponent;
