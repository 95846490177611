import {
  defaultTn,
  defaultTr,
  defaultVs30,
  filtersData,
  minSampleLimit,
  minZoom,
  flyToZoom,
} from '../../constants';
import { SET_DATA_RANGE, SET_MAX_DATA_RANGE, SET_SETTINGS } from './types';

const initialState = {
  currentZoom: minZoom,
  sampleLimit: minSampleLimit,
  currentVs30: defaultVs30,
  currentTn: defaultTn,
  currentTr: defaultTr,
  filters: filtersData,
  mapIsReady: false,
  boundingBox: [], // lngmin, lngmax,latmin,latmax
  flyToLatLon: [],
  chosenLonLat: [],
  nearestLonLat: [],
  chosenXY: [],
  dataRange: [],
  maxDataRange: [],
  PointLayerIsOn: false,
  boundingBoxFromMiniMap: [],
  pickedMapValue: null,
  mapCenter: {},
  activeRegions: [],
  flyToZoom: flyToZoom,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_DATA_RANGE:
      return {
        ...state,
        dataRange: action.payload.map((value, i) =>
          value === undefined ? state.dataRange[i] : value
        ),
      };

    case SET_MAX_DATA_RANGE:
      return {
        ...state,
        maxDataRange: action.payload,
      };

    default:
      return state;
  }
}
