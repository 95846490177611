import React from 'react';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors } from '../../redux';

import { Button, Modal } from 'react-bootstrap';

const ErrorComponent = () => {
  const { msg } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(clearErrors());

  return (
    <Modal show={msg} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Something went wrong</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please reload the page</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorComponent;
