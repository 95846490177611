import axios from 'axios';
import { RANGE_ENDPOINT } from '../../constants';
import { returnErrors } from '../error/actions';
import { SET_DATA_RANGE, SET_MAX_DATA_RANGE, SET_SETTINGS } from './types';

export const setSettings = (state) => (dispatch) => {
  dispatch({
    type: SET_SETTINGS,
    payload: state,
  });
};

export const setDataRange = (state) => (dispatch) => {
  axios
    .get(RANGE_ENDPOINT)
    .then((res) => {
      dispatch({
        type: SET_MAX_DATA_RANGE,
        payload: [res.data.sa_min, res.data.sa_max, res.data.pgv_min, res.data.pgv_max],
      });
      dispatch({
        type: SET_DATA_RANGE,
        payload: [
          res.data.sa_min_default,
          res.data.sa_max_default,
          res.data.pgv_min_default,
          res.data.pgv_max_default,
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(returnErrors('Error accessing csv file', ''));
    });
};

export const setCustomDataRange = (sa_min, sa_max, pgv_min, pgv_max) => (dispatch) => {
  dispatch({
    type: SET_DATA_RANGE,
    payload: [sa_min, sa_max, pgv_min, pgv_max],
  });
};
